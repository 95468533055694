import jQuery from 'jquery';
//import * as bootstrap from 'bootstrap';


window.jQuery = jQuery;
window.$ = jQuery;
//window.bootstrap = bootstrap;

//$.fn.extend(bootstrap);
//$.noConflict();
//export default { jQuery, bootstrap }

export default jQuery