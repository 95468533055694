/* eslint-disable */
import jqueryGlobal from './jqueryGlobal.js';
import { docReady } from "../utils";
import CryptoJS from 'crypto-js';

const sheesh = 'ce06ae67f1eed363da6b868d72402143c3e4900d79a79caa5e6772d2d8e8decc';

window.addEventListener('pageshow', (event) => {
	if (event.persisted) {
	  window.location.reload();
	}
});

function signIn() {
	$('#signIn').addClass('d-none');
	$('#pending').removeClass('d-none');
	if (access()) {
		console.log('good');
		setTimeout(() => {
			window.location.href = `${window.origin}/indexBarnBoss.html`;
		  }, 1500);
	} else {
		setTimeout(() => {
			$('#signIn').removeClass('d-none');
			$('#pending').addClass('d-none');
		  }, 1000);
		console.log("brrt");
	}
	
}

function access() {
	let pass = $('#accessCode').val();
	console.log(pass);
	const hash = CryptoJS.SHA256(pass).toString();
	console.log(hash);
	if (hash === sheesh) {
		return true
	} else {
		return false
	}
}

function addListeners() {
	$('#submitAccessCode').on('click', signIn)
}

docReady(addListeners);